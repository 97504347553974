import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { getDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db, auth } from "../../services/firebase";
import "./CryptoChatWallet.scss";
import * as solanaWeb3 from "@solana/web3.js";
import toast, { Toaster } from "react-hot-toast";
import bs58 from "bs58";
import Modal from "react-modal";

Modal.setAppElement("#root");

const CryptoChatWallet = () => {
  const [wallet, setWallet] = useState(null);
  const [, setLoading] = useState(false);
  const [ethereumWalletAddress, setEthereumWalletAddress] = useState(null);
  const [solanaWalletAddress, setSolanaWalletAddress] = useState(null);
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [educationalContent, setEducationalContent] = useState("");
  const [knowledgeLevel, setKnowledgeLevel] = useState("");
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Control modal visibility
  const [walletToDelete, setWalletToDelete] = useState(null); // Track wallet for deletion

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        const docSnapshot = await getDoc(userDoc);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.ethereumWalletAddress) {
            setEthereumWalletAddress(data.ethereumWalletAddress);
          }
          if (data.solanaWalletAddress) {
            setSolanaWalletAddress(data.solanaWalletAddress);
          }
          if (data.knowledgeLevel) {
            setKnowledgeLevel(data.knowledgeLevel);
          }
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleCreateEthereumWallet = async () => {
    setLoading(true);
    const newWallet = ethers.Wallet.createRandom();
    setWallet(newWallet);

    try {
      if (auth.currentUser) {
        const userDoc = doc(db, "users", auth.currentUser.uid);
        await setDoc(
          userDoc,
          { ethereumWalletAddress: newWallet.address },
          { merge: true }
        );
        setEthereumWalletAddress(newWallet.address);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error creating Ethereum wallet:", error);
    }

    setLoading(false);
  };

  const handleCreateSolanaWallet = async () => {
    setLoading(true);
    const newWallet = new solanaWeb3.Account();
    setWallet(newWallet);

    try {
      if (auth.currentUser) {
        const userDoc = doc(db, "users", auth.currentUser.uid);
        await setDoc(
          userDoc,
          { solanaWalletAddress: newWallet.publicKey.toString() },
          { merge: true }
        );
        setSolanaWalletAddress(newWallet.publicKey.toString());
        setIsModalOpen(true);
      }
    } catch (error) {
      toast.error("Error creating Solana wallet:", error);
    }

    setLoading(false);
  };

  const handleRemoveWalletDB = async (walletAddress, walletType) => {
    if (!auth.currentUser) {
      toast.error("User not authenticated.");
      return;
    }

    try {
      const userDoc = doc(db, "users", auth.currentUser.uid);

      if (walletType === "Ethereum") {
        await updateDoc(userDoc, {
          ethereumWalletAddress: "",
        });
        setEthereumWalletAddress(null);
        toast.success("Ethereum wallet removed.");
      } else if (walletType === "Solana") {
        await updateDoc(userDoc, {
          solanaWalletAddress: "",
        });
        setSolanaWalletAddress(null);
        toast.success("Solana wallet removed.");
      }
    } catch (error) {
      console.error("Error removing wallet from Firestore:", error);
      toast.error("Failed to remove wallet.");
    }
  };

  const handleSubmit = () => {
    if (!selectedWallet) {
      toast.error("Please select a wallet type.");
      return;
    }

    if (!knowledgeLevel) {
      toast.error("Please select your knowledge level.");
      return;
    }

    if (auth.currentUser) {
      const userDoc = doc(db, "users", auth.currentUser.uid);

      if (selectedWallet === "Ethereum" && ethereumWalletAddress) {
        toast.error(
          "Please delete your existing Ethereum wallet before creating a new one."
        );
        return;
      }

      if (selectedWallet === "Solana" && solanaWalletAddress) {
        toast.error(
          "Please delete your existing Solana wallet before creating a new one."
        );
        return;
      }

      if (selectedWallet === "Ethereum") {
        handleCreateEthereumWallet();
      } else if (selectedWallet === "Solana") {
        handleCreateSolanaWallet();
      }

      // Add knowledge level to Firestore
      updateDoc(userDoc, {
        knowledgeLevel: knowledgeLevel,
      })
        .then(() => {
          toast.success("Knowledge level updated in Firestore.");
        })
        .catch((error) => {
          console.error("Error updating knowledge level:", error);
          toast.error("Failed to update knowledge level.");
        });
    }
  };

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Address copied to clipboard");
  };

  const handleGoToEtherscan = (address) => {
    window.open(`https://etherscan.io/address/${address}`, "_blank");
  };

  const handleGoToSolscan = (address) => {
    window.open(`https://solscan.io/account/${address}`, "_blank");
  };

  const getPrivateKey = () => {
    if (wallet?.privateKey) {
      return wallet.privateKey;
    } else if (wallet?.secretKey) {
      return bs58.encode(wallet.secretKey);
    }
    return null;
  };

  const handleRerunContent = () => {
    // Clear educational content and trigger re-run
    setEducationalContent("");
    closeModal();
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    setShowPrivateKey(false);

    // const walletType = solanaWalletAddress ? "Solana" : "Ethereum";
    // const walletAddress = solanaWalletAddress || ethereumWalletAddress;

    // if (walletAddress) {
    //   try {
    //     const response = await fetch(
    //       "https://visiondesignr.com/solana_education.php",
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //           walletAddress,
    //           walletType,
    //           knowledgeLevel,
    //         }),
    //       }
    //     );
    //     const data = await response.json();
    //     const content = marked(
    //       data.response.candidates[0].content.parts[0].text
    //     );
    //     setEducationalContent(content);
    //     toast.success("Education content generated!");
    //   } catch (error) {
    //     console.error("Error generating educational content:", error);
    //     toast.error("Failed to generate educational content.");
    //   }
    // }
  };

  const handleKnowledgeLevelChange = async (level) => {
    setKnowledgeLevel(level);

     // Update the knowledge level in Firestore
  if (auth.currentUser) {
    try {
      const userDoc = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userDoc, {
        knowledgeLevel: level,
      });
      toast.success(`You selected: ${level}. Knowledge level updated.`);
    } catch (error) {
      console.error("Error updating knowledge level:", error);
      toast.error("Failed to update knowledge level.");
    }
  }
  };

  const handleSelectedWalletChange = (wallet) => {
    setSelectedWallet(wallet);
    toast.success(`You selected: ${wallet}`);
  };

  const confirmDelete = (walletAddress, walletType) => {
    setWalletToDelete({ walletAddress, walletType });
    setIsDeleteModalOpen(true); // Open the modal
  };

  const cancelDelete = () => {
    setWalletToDelete(null);
    setIsDeleteModalOpen(false); // Close the modal
  };

  const executeDelete = async () => {
    if (walletToDelete) {
      await handleRemoveWalletDB(
        walletToDelete.walletAddress,
        walletToDelete.walletType
      );
      setWalletToDelete(null);
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <div className="crypto-chat-wallet">
      {educationalContent === "" ? (
        <div>
          <div className="wallet-content">
            <div className="selected-wallet-container">
              <h2>Select Knowledge Level: </h2>
            </div>

            <div className="center-container">
              <div className="button-container">
                <button
                  className={`button-wallet solana ${
                    knowledgeLevel === "Beginner" ? "selected" : ""
                  }`}
                  onClick={() => handleKnowledgeLevelChange("Beginner")}
                >
                  Beginner
                  {knowledgeLevel === "Beginner" && (
                    <span className="material-icons checkmark">check</span>
                  )}
                </button>
                <button
                  className={`button-wallet solana ${
                    knowledgeLevel === "Intermediate" ? "selected" : ""
                  }`}
                  onClick={() => handleKnowledgeLevelChange("Intermediate")}
                >
                  Intermediate
                  {knowledgeLevel === "Intermediate" && (
                    <span className="material-icons checkmark">check</span>
                  )}
                </button>
                <button
                  className={`button-wallet solana ${
                    knowledgeLevel === "Advanced" ? "selected" : ""
                  }`}
                  onClick={() => handleKnowledgeLevelChange("Advanced")}
                >
                  Advanced
                  {knowledgeLevel === "Advanced" && (
                    <span className="material-icons checkmark">check</span>
                  )}
                </button>
              </div>
            </div>

            <div className="selected-wallet-container">
              <h2>Select Wallet Type: </h2>
            </div>

            <div className="wallet-btns">
              <div
                className={`wallet-section ${
                  selectedWallet === "Ethereum" ? "selectedWallet" : ""
                }`}
                onClick={() => handleSelectedWalletChange("Ethereum")}
              >
                <img
                  src="https://cryptologos.cc/logos/ethereum-eth-logo.png"
                  alt="Ethereum Icon"
                  style={{ marginBottom: "8px", width: "40px", height: "40px" }}
                />
                <h3>Ethereum Wallet</h3>

                <>
                  <p>
                    This Ethereum wallet allows you to securely store, send, and
                    receive Ether and other ERC-20 tokens. It provides a
                    user-friendly interface for managing your Ethereum assets.{" "}
                    <br />
                    <br />
                    <strong style={{ color: "red" }}>Important:</strong>
                    <strong>
                      Ensure you write down and keep your private key secure.
                      Losing it means losing access to your assets permanently.
                    </strong>
                    {ethereumWalletAddress && (
                      <>
                        <p className="wallet-address">
                          <strong>Ethereum Address:</strong>{" "}
                          {ethereumWalletAddress}
                        </p>
                        <button
                          className="button-wallet"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleCopyAddress(ethereumWalletAddress);
                          }}
                        >
                          Copy
                        </button>
                        <button
                          className="button-wallet"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleGoToEtherscan(ethereumWalletAddress);
                          }}
                        >
                          Go to Etherscan
                        </button>

                        <button
                          className="button-wallet"
                          onClick={(event) => {
                            event.stopPropagation();
                            confirmDelete(ethereumWalletAddress, "Ethereum");
                          }}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </p>
                  {selectedWallet === "Ethereum" && (
                    <span className="material-icons checkmark">check</span>
                  )}
                </>
              </div>
              <div
                className={`wallet-section solana ${
                  selectedWallet === "Solana" ? "selectedWallet" : ""
                }`}
                onClick={() => handleSelectedWalletChange("Solana")}
              >
                <img
                  src="https://cryptologos.cc/logos/solana-sol-logo.png"
                  alt="Solana Icon"
                  style={{ marginBottom: "8px", width: "40px", height: "40px" }}
                />
                <h3>Solana Wallet</h3>

                <>
                  <p>
                    This Solana wallet enables you to manage your SOL tokens and
                    other assets on the Solana blockchain. It offers a seamless
                    experience for interacting with decentralized applications
                    on Solana. <br /> <br />
                    <strong style={{ color: "red" }}>Important:</strong>
                    <strong>
                      Ensure you write down and keep your secret key secure.
                      Losing it means losing access to your assets permanently.
                    </strong>
                  </p>
                  {solanaWalletAddress && (
                    <>
                      <p className="wallet-address">
                        <strong>Solana Address:</strong> {solanaWalletAddress}
                      </p>
                      <button
                        className="button-wallet"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCopyAddress(solanaWalletAddress);
                        }}
                      >
                        Copy
                      </button>
                      <button
                        className="button-wallet"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleGoToSolscan(solanaWalletAddress);
                        }}
                      >
                        Go to Solscan
                      </button>
                      <button
                        className="button-wallet"
                        onClick={(event) => {
                          event.stopPropagation();
                          confirmDelete(solanaWalletAddress, "Solana");
                        }}
                      >
                        Delete
                      </button>
                    </>
                  )}
                  {selectedWallet === "Solana" && (
                    <span className="material-icons checkmark">check</span>
                  )}
                </>
              </div>
            </div>
          </div>

          <div className="disclaimer">
            <h4>
              <strong>Please read:</strong> *By creating a wallet, you are
              responsible for securing your private key. It will be provided
              once, and we cannot recover it if lost. We use AI for the process
              but do not access your private key or funds. Cryptocurrencies
              involve risk; use this service at your own discretion. Educational
              content provided is not financial advice.
            </h4>
          </div>

          {!(solanaWalletAddress && ethereumWalletAddress) ? (
            <button
              onClick={handleSubmit}
              className="card-button submit-button"
            >
              Submit
            </button>
          ) : null}
        </div>
      ) : (
        <>
          <button onClick={() => setEducationalContent("")} className="button">
            <span className="material-icons">navigate_before</span> Back
          </button>
          <div className="education-content">
            <button className="rerun-button" onClick={handleRerunContent}>
              <span className="material-icons">refresh</span>
              Refresh Content
            </button>
            <h3>Learn About {selectedWallet}</h3>
            <div
              className="education-box"
              dangerouslySetInnerHTML={{ __html: educationalContent }}
            />
          </div>
        </>
      )}

      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        className="modal-overlay"
        overlayClassName="delete-modal-overlay"
      >
        {/* Modal content goes here */}
        <div className="small-modal">
          <h4>Are you sure you want to delete this wallet?</h4>
          <div className="modal-buttons">
            <button className="modal-button yes" onClick={executeDelete}>
              Go Ahead
            </button>
            <button className="modal-button cancel" onClick={cancelDelete}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Private Key Modal"
        className="private-key-modal"
        overlayClassName="private-key-overlay"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <h2>Save Your Private Key</h2>
        <p>
          <strong style={{ color: "red" }}>Critical Warning:</strong> If you do
          not write down and save your key,{" "}
          <strong>
            all of your coins and transfers to this wallet will be lost forever.
          </strong>{" "}
          There is no way to recover your funds without this key.
        </p>
        <p>
          <strong>Private Key:</strong>{" "}
          {showPrivateKey ? (
            <>{getPrivateKey()}</>
          ) : (
            <button
              className="private-key"
              onClick={() => setShowPrivateKey(true)}
            >
              Show Private Key
            </button>
          )}
        </p>
        <button
          onClick={closeModal}
          disabled={!showPrivateKey} // Disable until private key is shown
          className={showPrivateKey ? "" : "disabled-button"}
        >
          I Understand
        </button>
      </Modal>
      <Toaster />
    </div>
  );
};

export default CryptoChatWallet;
