import React, { useEffect, useState, useRef } from "react";
import { auth, db } from "../../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import "./community.scss";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/spinner";
import Vapi from "@vapi-ai/web";
import toast, { Toaster } from "react-hot-toast";
import { marked } from "marked";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { useAudioRecorder } from "react-audio-voice-recorder";

const CommunityPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setResponse] = useState("");
  const [, setFullMessage] = useState(
    `Hi there! I'm here to help you learn about crypto and more. Just click the Coach to start our chat. *Mic needed.`
  );
  const [, setIsComplete] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [clickCount, setClickCount] = useState(15);
  const [isWaitingToSpeak, setIsWaitingToSpeak] = useState(false);
  const [canSpeak, setCanSpeak] = useState(false);
  const navigate = useNavigate();
  const recorder = useAudioRecorder(); // Initialize audio recorder
  let vapi = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      setLoading(false);

      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setClickCount(userDoc.data().aiCoachClickCount || 15);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!loading && !user) {
      navigate("/dashboard");
    }
  }, [loading, user, navigate]);

  const initializeVapi = () => {
    if (!vapi.current) {
      vapi.current = new Vapi(process.env.REACT_APP_VAPI_PRIVATE_KEY);
      //console.log("Vapi SDK initialized");
    }
  };

  const stopSpeaking = () => {
    if (vapi.current && isSpeaking) {
      vapi.current.stop();
      vapi.current = null;
      recorder.stopRecording(); // Stop recording when speaking ends
      setIsSpeaking(false);
      setIsWaitingToSpeak(false);
      setCanSpeak(false);
      toast("Speech stopped.");
    }
  };

  const handleVoiceInput = async () => {
    if (clickCount <= 0) {
      toast.error("You have reached the maximum number of interactions.");
      return;
    }

    if (!vapi.current) {
      initializeVapi();
    }

    if (vapi.current) {
      //console.log("Starting voice input...");
      setFullMessage("");
      setIsSpeaking(true);
      setIsWaitingToSpeak(true);
      setCanSpeak(false);

      let accumulatedMessage = "";
      setIsComplete(false);

      recorder.startRecording(); // Start recording audio when VAPI begins

      vapi.current.on("result", (result) => {
        //console.log("Voice input result:", result);
        if (result && result.text) {
          setResponse(result.text);
        } else {
          console.warn("No text found in the result:", result);
        }
      });

      vapi.current.on("message", (message) => {
        //console.log("Assistant message:", message);

        if (message.type === "model-output" && message.output) {
          let cleanedOutput = message.output
            .replace(/\s+([.,!?;:])/g, "$1")
            .replace(/(\d)\s+(\d)/g, "$1$2")
            .replace(/\*\*/g, "")
            .replace(/\s+/g, " ")
            .trim();

          accumulatedMessage += cleanedOutput + " ";
        }

        if (message.transcriptType === "final") {
          setIsComplete(true);
        }
      });

      vapi.current.on("end", () => {
        setFullMessage(marked(accumulatedMessage.trim()));
        setIsSpeaking(false);
        recorder.stopRecording(); // Stop recording at the end of the session
      });

      vapi.current.on("error", (error) => {
        //console.error("Voice input error:", error);
        toast.error(error.errorMsg || "An error occurred");
        setIsSpeaking(false);
        setIsWaitingToSpeak(false);
        setCanSpeak(false);
        recorder.stopRecording(); // Ensure recording stops if there's an error
      });

      try {
        vapi.current.start({
          transcriber: {
            provider: "deepgram",
            model: "nova-2",
            language: "en-US",
          },
          model: {
            provider: "openai",
            model: "gpt-4",
            messages: [
              {
                role: "system",
                content: "You are a helpful assistant.",
              },
            ],
          },
          voice: {
            provider: "playht",
            voiceId: "jennifer",
          },
          name: "Voice Assistant",
        });

        setTimeout(() => {
          setIsWaitingToSpeak(false);
          setCanSpeak(true);
        }, 1000);
      } catch (error) {
        //console.error("Failed to start VAPI session:", error);
        toast.error("Failed to start session: " + (error.message || error));
        setIsSpeaking(false);
      }
    } else {
      //console.error("Vapi SDK not initialized");
      toast.error("Vapi SDK not initialized");
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="community-page">
      <h1>Virtual AI Coach</h1>
      <div className="content">
        <div className="voice-button-text">Click below to try me!</div>
        <button
          className="voice-button"
          onClick={handleVoiceInput}
          disabled={isSpeaking || clickCount <= 0}
        >
          <div className="eye">
            <div className="pupil"></div>
            <div className="eyelid"></div>
          </div>
          <div className="eye">
            <div className="pupil"></div>
            <div className="eyelid"></div>
          </div>
          <div className="smile"></div>
        </button>

        {isSpeaking && (
          <button
            className="stop-button"
            onClick={stopSpeaking}
            disabled={!isSpeaking}
          >
            <i className="material-icons">stop</i> Stop Coach
          </button>
        )}

        <h4 className="ai-click">
          You have <strong style={{ color: "#3b56d3" }}>{clickCount}</strong>{" "}
          conversations left
        </h4>
        <p className="mic-required">
          <i className="material-icons">mic</i> Required*
        </p>
        {isWaitingToSpeak && <p className="wait-message">Wait to speak...</p>}

        {canSpeak && <p className="start-talking-message">Start speaking</p>}

        <div className="suggestion-cards-wrapper">
          <div className="suggestion-cards">
            <div className="card">
              <p>Help me understand Bitcoin basics</p>
              <i className="material-icons">format_quote</i>
            </div>
            <div className="card">
              <p>What is a blockchain?</p>
              <i className="material-icons">format_quote</i>
            </div>
            <div className="card">
              <p>How do I buy my first cryptocurrency?</p>
              <i className="material-icons">format_quote</i>
            </div>
            <div className="card">
              <p>Explain Ethereum to me.</p>
              <i className="material-icons">format_quote</i>
            </div>
          </div>
        </div>

        {isSpeaking &&
          recorder.mediaRecorder &&
          recorder.mediaRecorder.stream && (
            <LiveAudioVisualizer
              mediaRecorder={recorder.mediaRecorder} // Pass the correctly initialized media recorder
              width={500}
              height={75}
              barWidth={3}
              gap={2}
              barColor={"#3b56d3"}
            />
          )}

        {/* {fullMessage && (
          <div
            className="content-message"
            dangerouslySetInnerHTML={{ __html: fullMessage }} // Render HTML correctly
          />
        )} */}
      </div>
      <Toaster />
    </div>
  );
};

export default CommunityPage;
