// SmallLineGraph.js
import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

const SmallLineGraph = ({ data }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: data.map((_, index) => index), // Use indices as labels
        datasets: [
          {
            data,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true,
            tension: 0.4, // Smooth lines
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }, [data]);

  return (
    <div style={{ width: '100px', height: '50px' }}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default SmallLineGraph;
