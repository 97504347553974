import React, { useState, useEffect } from "react";
import "./CoinsPage.scss";
import {
  fetchTopMemecoins,
  fetchTopLayer1Coins,
  fetchTopSolanaCoins,
  fetchCoinsUnder1MMarketCap,
} from "../../api/coinmarketcap"; // Adjust the import path based on your file structure
import SmallLineGraph from "../../components/smallgraph";

const CoinsPage = () => {
  const [coins, setCoins] = useState([]);
  const [category, setCategory] = useState("memes");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let data;
      switch (category) {
        case "memes":
          data = await fetchTopMemecoins();
          break;
        case "layer-1":
          data = await fetchTopLayer1Coins();
          break;
        case "solana":
          data = await fetchTopSolanaCoins();
          break;
        case "under-1m":
          data = await fetchCoinsUnder1MMarketCap();
          break;
        default:
          data = await fetchTopMemecoins();
      }
      setCoins(data);
      setLoading(false);
    };
    fetchData();
  }, [category]);

  return (
    <div className="coins-page">
      <h1>Top Coins</h1>
      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="coin-container">
            <div className="buttons-container">
              <button onClick={() => setCategory("memes")}>
                <div className="icon">
                  <img
                    width="25"
                    src="https://img.icons8.com/?size=100&id=5BZmf_27QkFi&format=png&color=000000"
                  />
                </div>
                <div className="label">Top Memecoins</div>
              </button>
              <button onClick={() => setCategory("layer-1")}>
                <div className="icon">
                  <img
                    width="25"
                    src="https://img.icons8.com/?size=100&id=100819&format=png&color=000000"
                  />
                </div>
                <div className="label">Top Layer 1 Coins</div>
              </button>
              <button onClick={() => setCategory("solana")}>
                <div className="icon">
                  <img
                    width="25"
                    src="https://img.icons8.com/?size=100&id=NgbFFSOCkrnB&format=png&color=000000"
                  />
                </div>
                <div className="label">Top Solana Coins</div>
              </button>
            </div>
            <div className="coins-grid">
              {coins.map((coin) => {
                const priceChanges24h = coin.quote?.USD?.price_changes_24h; // Adjust this based on your actual data structure
                return (
                  <div
                    className={
                      coin.quote.USD.percent_change_24h > 0
                        ? "coin-card positive-card"
                        : "coin-card negative-card"
                    }
                    key={coin.id}
                  >
                    <a
                      href={`https://coinmarketcap.com/currencies/${coin.slug}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${coin.id}.png`}
                        alt={coin.name}
                      />
                      <div className="coin-name">{coin.name}</div>
                    </a>
                    <div className="coin-price">
                      <strong>Price:</strong> ${coin.quote.USD.price.toFixed(8)}
                    </div>
                    <div className="coin-market-cap">
                      <strong>Market Cap:</strong> $
                      {coin.quote.USD.market_cap.toLocaleString()}
                    </div>
                    <div className="coin-volume">
                      <strong>Volume (24h):</strong> $
                      {coin.quote.USD.volume_24h.toLocaleString()}
                    </div>
                    <div
                      className={`coin-change ${
                        coin.quote.USD.percent_change_24h > 0
                          ? "positive"
                          : "negative"
                      }`}
                    >
                      <strong>Change (24h):</strong>{" "}
                      {coin.quote.USD.percent_change_24h.toFixed(2)}%
                    </div>
                    {/* Ensure SmallLineGraph data prop is correctly passed and is an array */}
                    {Array.isArray(priceChanges24h) && (
                      <SmallLineGraph data={priceChanges24h} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CoinsPage;
