import React, { useState, useEffect } from "react";
import { fetchMarketNews } from "../../api/coinmarketnews"; // Adjust the import path based on your file structure
import "./market-news.scss";

const MarketNewsPage = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const defaultImageUrls = [
    "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
    "https://cryptologos.cc/logos/ethereum-eth-logo.png"
  ];

  const getRandomDefaultImage = () => {
    const randomIndex = Math.floor(Math.random() * defaultImageUrls.length);
    return defaultImageUrls[randomIndex];
  };

  const truncateTitle = (title, maxLength = 60) => {
    if (title.length > maxLength) {
      return title.slice(0, maxLength) + "...";
    }
    return title;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const articles = await fetchMarketNews();
        setNews(articles);
      } catch (error) {
        console.error("Failed to fetch market news", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="market-news-page">
      <h1>Market News</h1>
      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="content">
          <div className="news-grid">
            {news.map((article, index) => {
              const missingImage = "missing_large.png";
              const imageUrl = article.thumb_2x === missingImage ? null : article.thumb_2x;

              return (
                <div className="news-card" key={index}>
                  <img src={imageUrl || getRandomDefaultImage()} alt={article.title} />
                  <div className="news-info">
                    <h2>
                      <a
                        href={article.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="title"
                      >
                        {truncateTitle(article.title)}
                      </a>
                    </h2>
                    <p>{article.news_site}</p>
                    <p>{new Date(article.updated_at * 1000).toLocaleString()}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketNewsPage;
