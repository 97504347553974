import axios from 'axios';

const fetchMarketData = async () => {
  try {
    const response = await axios.get('https://www.visiondesignr.com/fetch_market_data.php');
    console.log('Fetched Market Data:', response.data.data); // Log data for debugging
    return response.data.data;
  } catch (error) {
    console.error('Error fetching market data:', error);
    return [];
  }
};

const memeCoinList = ['DOGE', 'SHIB', 'TRUMP', 'ELON', 'HOGE', 'SMI', 'AKITA', 'KISHU', 'PIT', 'FLOKI', 'SAMO', 'INU', 'MONA', 'BAN', 'POLYDOGE', 'BABYDOGE', 'MARS', 'KITTY', 'RDD', 'CATE', 'PEPE', 'BRETT'];
const layer1CoinList = ['ETH', 'BTC', 'SOL', 'ADA', 'DOT', 'AVAX', 'LUNA', 'ALGO', 'EGLD', 'ONE', 'NEAR', 'BNB', 'ATOM', 'FTM', 'TRX', 'XTZ', 'KSM', 'CELO', 'ICP', 'HBAR'];

export const fetchTopMemecoins = async () => {
  try {
    const data = await fetchMarketData();
    const memeCoins = data.filter(coin => memeCoinList.includes(coin.symbol));
    console.log('Fetched Meme Coins:', memeCoins);
    return memeCoins.slice(0, 20); // Return top 20 meme coins
  } catch (error) {
    console.error('Error fetching meme coins:', error);
    return [];
  }
};

export const fetchTopLayer1Coins = async () => {
  try {
    const data = await fetchMarketData();
    const layer1Coins = data.filter(coin => layer1CoinList.includes(coin.symbol));
    console.log('Fetched Layer 1 Coins:', layer1Coins);
    return layer1Coins.slice(0, 20); // Return top 20 Layer 1 coins
  } catch (error) {
    console.error('Error fetching Layer 1 coins:', error);
    return [];
  }
};

export const fetchTopSolanaCoins = async () => {
  try {
    const data = await fetchMarketData();
    const solanaCoins = data.filter(coin => coin.platform && coin.platform.slug === 'solana');
    console.log('Fetched Solana Coins:', solanaCoins);
    return solanaCoins;
  } catch (error) {
    console.error('Error fetching Solana coins:', error);
    return [];
  }
};

export const fetchCoinsUnder1MMarketCap = async () => {
  try {
    const data = await fetchMarketData();
    const coinsUnder1M = data.filter(coin => coin.quote.USD.market_cap < 1000000);
    console.log('Fetched Coins Under 1M Market Cap:', coinsUnder1M);
    return coinsUnder1M;
  } catch (error) {
    console.error('Error fetching coins under 1M market cap:', error);
    return [];
  }
};
