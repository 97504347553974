import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./pages/landing-page";
import CoinsPage from "./pages/coins-page"; // Adjust the path as needed
import { auth } from "./services/firebase"; // Adjust the import path if necessary
import MarketNewsPage from "./pages/marketnews";
import WalletPage from "./pages/wallet-page";
import CommunityPage from "./pages/community-page"; // Import CommunityPage
import { Toaster } from "react-hot-toast";
import { UserProvider } from "./contexts/UserContext";

function App() {
  const [user, setUser] = useState(null);
  const [, setLoading] = useState(true);

  useEffect(() => {
    // Check if user is logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false); // Set loading to false after checking auth state
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);


  return (
    <UserProvider>
      <Router>
          <Routes>
            <Route path="/" element={<Home user={user} />} />
            <Route path="/coins" element={<CoinsPage />} />
            <Route path="/wallet" element={<WalletPage />} />
            <Route path="/marketnews" element={<MarketNewsPage />} />
            <Route path="/aicoach" element={<CommunityPage />} />{" "}
            {/* Add CommunityPage route */}
          </Routes>
        <Toaster />
      </Router>
    </UserProvider>
  );
}

export default App;
