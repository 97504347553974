import React, { useEffect, useState } from "react";
import { auth } from "../../services/firebase";
import CryptoChatWallet from "../../components/cryptochatwallet"; // Adjust the import path based on your file structure
import "./wallet.scss";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Spinner from "../../components/spinner";

const WalletPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!loading && !user) {
      navigate("/dashboard"); // Navigate to login if user is not authenticated
    }
  }, [loading, user, navigate]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="wallet-page">
      <h1>Wallet</h1>
      <div className="content">
        <CryptoChatWallet />
      </div>
    </div>
  );
};

export default WalletPage;
