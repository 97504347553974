import axios from 'axios';

export const fetchMarketNews = async () => {
  try {
    const response = await axios.get('https://www.visiondesignr.com/fetch_market_news.php');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching market news:', error);
    return [];
  }
};
